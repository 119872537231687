/**
 * ContextPopup.js
 * 
 * A React component that displays source documents and their relevance across multiple subqueries.
 * This component handles document highlighting, search functionality, and interaction with source
 * documents referenced in the system's response.
 * 
 * Key Features:
 * - Displays source documents with their relevance across subqueries
 * - Provides search functionality within document content
 * - Highlights documents that are referenced in the system response
 * - Supports interaction through likes, dislikes, and copying
 * - Maintains consistent document highlighting across multiple subqueries
 */

import React, { useState, useRef, useCallback, useMemo } from 'react';
import { ThumbsUp, ThumbsDown, Copy, FileText, X, Search } from 'lucide-react';

/**
 * Normalizes document IDs to a consistent format.
 * Extracts doc_X pattern from various possible ID formats.
 * 
 * @param {string|null} id - The document ID to normalize
 * @returns {string|null} - Normalized document ID or null if invalid
 */
const normalizeSourceId = (id) => {
  if (!id) return null;
  const match = id.toString().match(/doc_\d+/);
  return match ? match[0] : null;
};

/**
 * Finds all unique document references within a text.
 * Handles multiple document IDs within a single source citation.
 * 
 * @param {string} text - The text to search for document references
 * @returns {string[]} - Array of unique document IDs
 */
const findDocumentReferences = (text) => {
  // Match all source citations, including those with multiple documents
  const matches = text.match(/\(Source: (doc_\d+)(?:,\s*doc_\d+)*\)/g) || [];
  const docIds = new Set();
  
  // Extract individual document IDs from each citation
  matches.forEach(match => {
    const ids = match.match(/doc_\d+/g) || [];
    ids.forEach(id => docIds.add(id));
  });
  
  return Array.from(docIds);
};

/**
 * ContextPopup Component
 * Displays and manages interactions with source documents from the query response.
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Controls visibility of the popup
 * @param {function} props.onClose - Handler for closing the popup
 * @param {string} props.context - Raw context containing document content
 * @param {string} props.systemResponse - Complete system response with citations
 * @param {boolean} props.isDarkMode - Theme toggle
 * @param {function} props.handleDocumentClick - Handler for document selection
 * @param {Object} props.idMapping - Maps document IDs to their references
 * @param {function} props.addToClipboard - Handler for copying text
 */
const ContextPopup = ({ 
  isOpen, 
  onClose, 
  context, 
  systemResponse, 
  isDarkMode, 
  handleDocumentClick, 
  idMapping,
  addToClipboard
}) => {
  // State management for search and interaction
  const [searchTerm, setSearchTerm] = useState('');
  const [matchCount, setMatchCount] = useState(0);
  const scrollRef = useRef(null);
  const [clickedIcons, setClickedIcons] = useState({});
  const [tooltipContent, setTooltipContent] = useState(null);
  const [hoveredDoc, setHoveredDoc] = useState(null);

  /**
   * Handles user feedback submissions for document content.
   * Sends feedback to the API for tracking and improvement.
   */
  const handleFeedback = useCallback(async (text, feedbackType) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000'}/api/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_query: text,
          system_output: systemResponse,
          feedback: feedbackType === 'like' ? 'good' : 'bad'
        }),
      });
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  }, [systemResponse]);

  const handleMouseEnter = async (docId) => {
    setHoveredDoc(docId);
    const originalId = Object.entries(idMapping)
      .find(([_, mappedId]) => mappedId === docId)?.[0];
  
    if (originalId) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000'}/api/get_doc_info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ docId: originalId })
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch tooltip data');
        }
  
        const data = await response.json();
        if (data.status === 'success') {
          setTooltipContent(data);
        }
      } catch (error) {
        console.error('Error fetching tooltip data:', error);
      }
    }
  };

  const handleMouseLeave = () => {
    setHoveredDoc(null);
    setTooltipContent(null);
  };

  const processTextWithIcons = useCallback((text, sourceIds) => {
    const parts = text.split(/(\(Source: [^,\)]+(?:, Page: \d+)?\))/g);
    return parts.map((part, index) => {
      if (part.startsWith('(Source:')) {
        const sourceMatch = part.match(/doc_\d+/);
        const sourceId = sourceMatch ? sourceMatch[0] : null;
        return (
          <div
            key={`icon-${index}`}
            style={{ 
              position: 'relative',
              display: 'inline-block'
            }}
          >
            <FileText
              size={16}
              onClick={() => handleDocumentClick(sourceId, idMapping)}
              onMouseEnter={() => handleMouseEnter(sourceId)}
              onMouseLeave={handleMouseLeave}
              style={{
                display: 'inline-block',
                marginLeft: '4px',
                marginRight: '4px',
                cursor: 'pointer',
                color: isDarkMode ? '#FF79C6' : '#bd93f9',
                verticalAlign: 'middle'
              }}
            />
            {hoveredDoc === sourceId && tooltipContent && (
              <div
                onMouseLeave={handleMouseLeave}
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: isDarkMode ? '#2C2C2C' : '#FFFFFF',
                  border: `2px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
                  borderRadius: '8px',
                  padding: '12px',
                  zIndex: 1000,
                  minWidth: '300px',
                  maxWidth: '500px',
                  boxShadow: `0 4px 12px ${isDarkMode ? 'rgba(255, 121, 198, 0.2)' : 'rgba(189, 147, 249, 0.2)'}`,
                  color: isDarkMode ? '#E0E0E0' : '#333333',
                  fontSize: '14px',
                  whiteSpace: 'normal',
                  textAlign: 'left',
                  pointerEvents: 'auto'
                }}
              >
                <div 
                  style={{ 
                    fontWeight: 'bold',
                    fontSize: '14px',
                    marginBottom: '12px',
                    borderBottom: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
                    paddingBottom: '8px',
                    color: isDarkMode ? '#FF79C6' : '#bd93f9'
                  }}
                >
                  {tooltipContent.filename}
                </div>
                {tooltipContent.reference && (
                  <div 
                    style={{ 
                      marginBottom: '12px',
                      fontSize: '14px',
                      color: isDarkMode ? '#cccccc' : '#666666'
                    }}
                  >
                    <span style={{ fontWeight: '500' }}>Reference:</span> {tooltipContent.reference}
                  </div>
                )}
                <div 
                  style={{ 
                    fontSize: '14px',
                    lineHeight: '1.5',
                    maxHeight: '600px',
                    overflowY: 'auto'
                  }}
                >
                  {tooltipContent.text_representation}
                </div>
              </div>
            )}
          </div>
        );
      }
      return part;
    });
  }, [handleDocumentClick, idMapping, isDarkMode, hoveredDoc, tooltipContent]);
  
  // /**
  //  * Processes text to include interactive document icons.
  //  * Converts source citations into clickable document icons.
  //  */
  // const processTextWithIcons = useCallback((text, sourceIds) => {
  //   const parts = text.split(/(\(Source: [^,\)]+(?:, Page: \d+)?\))/g);
  //   return parts.map((part, index) => {
  //     if (part.startsWith('(Source:')) {
  //       const sourceMatch = part.match(/doc_\d+/);
  //       const sourceId = sourceMatch ? sourceMatch[0] : null;
  //       return (
  //         <FileText
  //           key={`icon-${index}`}
  //           size={16}
  //           onClick={() => handleDocumentClick(sourceId, idMapping)}
  //           style={{
  //             display: 'inline-block',
  //             marginLeft: '4px',
  //             marginRight: '4px',
  //             cursor: 'pointer',
  //             color: isDarkMode ? '#FF79C6' : '#bd93f9',
  //             verticalAlign: 'middle'
  //           }}
  //         />
  //       );
  //     }
  //     return part;
  //   });
  // }, [handleDocumentClick, idMapping, isDarkMode]);

  /**
   * Highlights search terms within document text while preserving icons.
   * Maintains document icons and their functionality while adding search highlighting.
   */
  const highlightText = useCallback((text, sourceIds) => {
    const textWithIcons = processTextWithIcons(text, sourceIds);
    
    if (!searchTerm.trim()) {
      return textWithIcons;
    }

    return textWithIcons.map((element, index) => {
      if (React.isValidElement(element)) {
        return element;
      }

      const searchParts = element.split(new RegExp(`(${searchTerm})`, 'gi'));
      return searchParts.map((part, searchIndex) => 
        part.toLowerCase() === searchTerm.toLowerCase() 
          ? <mark 
              key={`${index}-${searchIndex}`} 
              style={{
                backgroundColor: isDarkMode ? '#FF79C6' : '#bd93f9',
                color: isDarkMode ? '#1E1E1E' : '#FFFFFF',
                padding: '0.1em 0.2em',
                borderRadius: '2px'
              }}
            >
              {part}
            </mark>
          : part
      );
    });
  }, [searchTerm, isDarkMode, processTextWithIcons]);

  /**
   * Processes and organizes paragraphs from the context.
   * Handles multi-subquery document tracking and relevance scoring.
   */
  const paragraphs = useMemo(() => {
    if (!context) return [];
    
    // Extract all document references from the complete system response
    const usedDocIds = findDocumentReferences(systemResponse);
    
    // Process each paragraph from the context
    const chunks = context
      .split('\n\n\n')
      .filter(p => p.trim())
      .map((paragraph, idx) => {
        // Extract document IDs and check usage in system response
        const sourceMatches = paragraph.match(/doc_\d+/g) || [];
        const isUsed = sourceMatches.some(id => usedDocIds.includes(id));

        // Parse relevance score (indicates subquery appearances)
        const relevanceMatch = paragraph.match(/RELEVANCE SCORE: (\d+)/);
        const relevanceScore = relevanceMatch ? parseInt(relevanceMatch[1]) : 1;

        // Extract clean content from between markers
        let content = paragraph;
        const contentMatch = paragraph.match(/BEGIN DOCUMENT CONTENT\n([\s\S]*?)\nEND DOCUMENT CONTENT/);
        if (contentMatch) {
          content = contentMatch[1].trim();
        }

        return {
          text: content,
          uniqueId: `paragraph-${idx}`,
          sourceIds: sourceMatches,
          isUsed,
          relevanceScore,
          subqueryCount: relevanceScore
        };
      });

    // Sort by relevance score (higher scores first)
    return chunks.sort((a, b) => b.relevanceScore - a.relevanceScore);
  }, [context, systemResponse]);

  /**
   * Filters paragraphs based on search term.
   * Handles search functionality and match counting.
   */
  const filteredParagraphs = useMemo(() => {
    if (!searchTerm.trim()) {
      setMatchCount(0);
      return paragraphs;
    }
    
    try {
      const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const searchRegExp = new RegExp(escapedSearchTerm, 'gi');
      
      let totalMatches = 0;
      const filtered = paragraphs.filter(paragraph => {
        const textWithoutSources = paragraph.text.replace(/\(Source: [^)]+\)/g, '');
        const matches = (textWithoutSources.match(searchRegExp) || []).length;
        totalMatches += matches;
        return matches > 0;
      });
  
      setMatchCount(totalMatches);
      return filtered;
    } catch (error) {
      console.error('RegExp error:', error);
      return paragraphs;
    }
  }, [paragraphs, searchTerm]);

  /**
   * Renders individual paragraphs with their metadata and interactions.
   * Handles document highlighting and user interactions.
   */
  const MemoizedParagraph = React.memo(({
    paragraph,
    index,
    isDarkMode,
    handleFeedback,
    addToClipboard,
    highlightText
  }) => {
    const { text, sourceIds, isUsed, subqueryCount } = paragraph;

    const handleIconClick = (iconType, action) => {
      setClickedIcons(prev => ({
        ...prev,
        [`${iconType}-${index}`]: true
      }));

      action();

      setTimeout(() => {
        setClickedIcons(prev => ({
          ...prev,
          [`${iconType}-${index}`]: false
        }));
      }, 300);
    };

    return (
      <div 
        style={{
          padding: '12px',
          marginBottom: '8px',
          borderRadius: '6px',
          backgroundColor: isUsed 
            ? (isDarkMode ? 'rgba(255, 121, 198, 0.15)' : 'rgba(189, 147, 249, 0.15)')
            : (isDarkMode ? '#1E1E1E' : '#F8F8F8'),
          border: `1px solid ${isUsed 
            ? (isDarkMode ? '#FF79C6' : '#bd93f9')
            : (isDarkMode ? '#3d3d3d' : '#e1e1e1')}`,
        }}
      >
        {subqueryCount > 1 && (
          <div style={{
            fontSize: '0.85em',
            color: isDarkMode ? '#FF79C6' : '#bd93f9',
            marginBottom: '8px'
          }}>
            Found in {subqueryCount} subqueries
          </div>
        )}

        <div>{highlightText(text, sourceIds)}</div>
        
        <div style={{
          display: 'flex',
          gap: '4px',
          marginTop: '8px'
        }}>
          {[
            { type: 'like', Icon: ThumbsUp, action: () => handleFeedback(text, 'like') },
            { type: 'dislike', Icon: ThumbsDown, action: () => handleFeedback(text, 'dislike') },
            { type: 'copy', Icon: Copy, action: () => addToClipboard(text) }
          ].map(({ type, Icon, action }) => (
            <button
              key={type}
              onClick={() => handleIconClick(type, action)}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                padding: '4px',
                borderRadius: '4px',
                color: clickedIcons[`${type}-${index}`] ? 'white' : (isDarkMode ? '#666666' : '#999999'),
                backgroundColor: clickedIcons[`${type}-${index}`] ? (isDarkMode ? '#3498DB' : '#2980B9') : 'transparent',
                transition: 'color 0.2s ease, transform 0.2s ease, background-color 0.3s'
              }}
              title={type.charAt(0).toUpperCase() + type.slice(1)}
            >
              <Icon size={16} />
            </button>
          ))}
        </div>
      </div>
    );
  });

  // Don't render if popup is closed
  if (!isOpen) return null;

  // Main popup render
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}>
      <div style={{
        width: '70%',
        height: '85%',
        backgroundColor: isDarkMode ? '#252525' : '#FFFFFF',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        border: `2px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`
      }}>
        {/* Header section with title and search */}
        <div style={{
          padding: '16px',
          borderBottom: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
          display: 'flex',
          flexDirection: 'column',
          gap: '12px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <h2 style={{
              margin: 0,
              color: isDarkMode ? '#FFFFFF' : '#000000',
              fontSize: '1.25rem',
              marginLeft: '10px'
            }}>
              Source Documents ({paragraphs.length})
            </h2>
            <button
              onClick={onClose}
              style={{
                background: 'none',
                border: 'none',
                color: isDarkMode ? '#FF79C6' : '#bd93f9',
                cursor: 'pointer'
              }}
            >
              <X size={24} />
            </button>
          </div>

          {/* Search input with icon */}
          <div style={{ 
            position: 'relative',
            margin: '0 7px'
          }}>
            <Search 
              style={{
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: isDarkMode ? '#666666' : '#999999'
              }}
              size={18}
            />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search in content..."
              style={{
                width: '100%',
                padding: '12px 32px 12px 34px',
                borderRadius: '6px',
                border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
                backgroundColor: isDarkMode ? '#1E1E1E' : '#F8F8F8',
                color: isDarkMode ? '#FFFFFF' : '#000000',
                fontSize: '0.95rem',
                boxSizing: 'border-box'
              }}
            />
            {/* Show match count when searching */}
            {searchTerm.trim() && (
              <div style={{
                position: 'absolute',
                right: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                fontSize: '0.85em',
                color: isDarkMode ? '#FF79C6' : '#bd93f9'
              }}>
                {matchCount} {matchCount === 1 ? 'match' : 'matches'}
              </div>
            )}
          </div>
        </div>

        {/* Main content area with scrollable paragraphs */}
        <div 
          ref={scrollRef}
          style={{
            flex: 1,
            overflowY: 'auto',
            padding: '16px',
            backgroundColor: isDarkMode ? '#252525' : '#FFFFFF',
            color: isDarkMode ? '#FFFFFF' : '#000000'
          }}
        >
          {/* Render filtered paragraphs */}
          {filteredParagraphs.map((paragraph, index) => 
            <MemoizedParagraph
              key={paragraph.uniqueId}
              paragraph={paragraph}
              index={index}
              isDarkMode={isDarkMode}
              handleFeedback={handleFeedback}
              addToClipboard={addToClipboard}
              highlightText={highlightText}
            />
          )}
          
          {/* Show "No matches" message when search yields no results */}
          {searchTerm.trim() && filteredParagraphs.length === 0 && (
            <div style={{
              textAlign: 'center',
              padding: '2rem',
              color: isDarkMode ? '#666666' : '#999999'
            }}>
              No matches found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContextPopup;