import React, { useState, useRef, useEffect, useMemo, useCallback, useLayoutEffect } from 'react';
import { Settings, Upload, Send, ChevronLeft, ChevronRight, Sun, Moon, HelpCircle, Image, Plus, Paperclip, LogOut, FileText, Sparkle, BookOpenText, CircleX, X, HelpingHand, Pencil } from 'lucide-react';
import { ThumbsUp, ThumbsDown, Copy, CloudDownload,RefreshCw, ArrowUp, Octagon, Search, FileSearch, Edit, Clipboard, ClipboardList } from 'lucide-react';
import LoadingIndicator from './LoadingIndicator';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './LoginButton';
import ContentEditableInput from './ContentEditableInput';
import SkeletonLoader from './SkeletonLoader'; 
import HelpPopup from './HelpPopup';
import ClipboardPanel from './ClipboardPanel';
import ContextPopup from './ContextPopup';
import KLimitSetting from './KLimitSetting';
import BatchQueriesPopup from './BatchQueriesPopup';
import PDFViewer from './PDFViewer';
import EditableMessage from './EditableMessage';
import ReactMarkdown from 'react-markdown';
import './MarkdownStyles.css';

// import * as pdfjsLib from 'pdfjs-dist/webpack';
// const pdfjsVersion = '4.0.379';
// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`;

// const API_ENDPOINT = "https://4b7665dd-98cb-47af-9795-d3d8ec0064a6-00-334wn4gxieb85.pike.replit.dev";

// const API_ENDPOINT = "http://localhost:5000/api/query";
// const FEEDBACK_ENDPOINT = "http://localhost:5000/api/feedback";
// const RECENT_CHATS_ENDPOINT = "http://localhost:5000/api/recent_chats";
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://ragbot2-app-7a8e4854488e.herokuapp.com';
const API_BASE_URL = 'https://ragbot2-app-7a8e4854488e.herokuapp.com';
// const API_BASE_URL = "http://localhost:5000"; # Use for local hosting
const API_ENDPOINT = `${API_BASE_URL}/api/query`;
const FEEDBACK_ENDPOINT = `${API_BASE_URL}/api/feedback`;
const RECENT_CHATS_ENDPOINT = `${API_BASE_URL}/api/recent_chats`;

const App = () => {
  const [message, setMessage] = useState("");
  const [conversation, setConversation] = useState([]);
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false); 
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [currentQueryIndex, setCurrentQueryIndex] = useState(0);
  const [recentlyClicked, setRecentlyClicked] = useState({});
  const textareaRef = useRef(null);
  const conversationRef = useRef(null);
  const [recentChats, setRecentChats] = useState([]);
  const [pdfPages, setPdfPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pdfImage, setPdfImage] = useState(null);
  const [isRightSidebarVisible, setIsRightSidebarVisible] = useState(false);
  const [hasSystemOutput, setHasSystemOutput] = useState(false);
  const [isNewChat, setIsNewChat] = useState(true);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeDocumentId, setActiveDocumentId] = useState(null);
  const [idMapping, setIdMapping] = useState({});
  // const [isMoreClicked, setIsMoreClicked] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [contextAlt, setContextAlt] = useState("");
  const [clickedIcons, setClickedIcons] = useState({});
  const settingsMenuRef = useRef(null);
  const settingsButtonRef = useRef(null);
  // const popupRef = useRef(null);
  const [isQueryPending, setIsQueryPending] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  const [isHelpPopupOpen, setIsHelpPopupOpen] = useState(false);
  const [helpPopupContent, setHelpPopupContent] = useState('');
  const [isHelpLoading, setIsHelpLoading] = useState(false);
  // const [pdfUrl, setPdfUrl] = useState(null);
  // const [isRegenerating, setIsRegenerating] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [pdfBase64, setPdfBase64] = useState(null);
  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const { isAuthenticated, isLoading2 } = useAuth0();
  const [leftSidebarWidth, setLeftSidebarWidth] = useState(260); // in pixels
  const [rightSidebarWidth, setRightSidebarWidth] = useState(40); // as a percentage
  const [currentIdMapping, setCurrentIdMapping] = useState({});
  const [refreshDownloadIcon, setRefreshDownloadIcon] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [pdfImageUrl, setPdfImageUrl] = useState(null);
  const [pdfError, setPdfError] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [serverError, setServerError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [bboxPage, setBboxPage] = useState(null);
  const { user } = useAuth0();
  const [isFilteredSearch, setIsFilteredSearch] = useState(false);
  const [filteredFileName, setFilteredFileName] = useState(null);
  const [messageIconStates, setMessageIconStates] = useState({});
  const [activeDocRef, setActiveDocRef] = useState({ messageIndex: null, docId: null });
  const [displayMode, setDisplayMode] = useState('filename'); // 'filename' or 'reference'
  const [currentReference, setCurrentReference] = useState('');
  const [isEditingRef, setIsEditingRef] = useState(false);
  const [editedRef, setEditedRef] = useState('');
  // const [documentReferences, setDocumentReferences] = useState({});
  const [isClipboardOpen, setIsClipboardOpen] = useState(false);
  const [clipboardItems, setClipboardItems] = useState([]);
  const [kLimit, setKLimit] = useState(20);
  const [isKLimitSettingsOpen, setIsKLimitSettingsOpen] = useState(false);
  const [isBatchQueriesOpen, setIsBatchQueriesOpen] = useState(false);
  const [editingMessageIndex, setEditingMessageIndex] = useState(null);
  const [systemResponseForContext, setSystemResponseForContext] = useState("");
  const [pdfDocument, setPdfDocument] = useState(null);
  const [isInitialDocumentLoad, setIsInitialDocumentLoad] = useState(true);
  const [activeMessageIndex, setActiveMessageIndex] = useState(null);
  const [activeDocRefs, setActiveDocRefs] = useState({}); 
  const [tooltipContent, setTooltipContent] = useState(null);
  const [hoveredDoc, setHoveredDoc] = useState(null);
  const [activeTooltipId, setActiveTooltipId] = useState(null);
  
  const { logout } = useAuth0();

  const addToClipboard = useCallback((text) => {
    setClipboardItems(prevItems => [
      {
        text,
        timestamp: new Date().toISOString()
      },
      ...prevItems
    ]);
  }, []);

  const handleEditMessage = (index) => {
    setEditingMessageIndex(index);
  };
  
  const handleSaveEdit = async (newText, index) => {
    if (newText.trim() === conversation[index].text.trim()) {
      setEditingMessageIndex(null);
      return;
    }
  
    // Update the conversation with the edited message
    const newConversation = [...conversation];
    newConversation[index] = { ...newConversation[index], text: newText };
    setConversation(newConversation);
    setEditingMessageIndex(null);
  
    // If this was a user message, regenerate the bot response
    if (newConversation[index].from === 'user' && index + 1 < newConversation.length) {
      await sendMessageStream(newText);
    }
  };
  
  const handleCancelEdit = () => {
    setEditingMessageIndex(null);
  };

  const systemResponse = useMemo(() => conversation[conversation.length - 1]?.text || '', [conversation]);

  const removeClipboardItem = (index) => {
    setClipboardItems(prevItems => prevItems.filter((_, i) => i !== index));
  };

  const clearClipboard = () => {
    setClipboardItems([]);
  };

  const toggleClipboard = () => {
    setIsClipboardOpen(!isClipboardOpen);
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  // const handleContentEditableInput = (content) => {
  //   setMessage(content);
  // };

  const enableFilteredSearch = () => {
    if (fileName) {
      console.log('Enabling filtered search for file:', fileName);
      setIsFilteredSearch(true);
      setFilteredFileName(fileName);
      // Add visual feedback
      const fileSearchIcon = document.querySelector('.file-search-icon');
      if (fileSearchIcon) {
        fileSearchIcon.style.color = isDarkMode ? '#FF79C6' : '#bd93f9';
      }
    }
  };

  const disableFilteredSearch = () => {
    console.log('Disabling filtered search');
    setIsFilteredSearch(false);
    setFilteredFileName(null);
    // Reset visual feedback
    const fileSearchIcon = document.querySelector('.file-search-icon');
    if (fileSearchIcon) {
      fileSearchIcon.style.color = isDarkMode ? '#E0E0E0' : '#333333';
    }
  };

  const calculateCenterAreaWidth = () => {
    if (!isSidebarVisible && !isRightSidebarVisible) {
      return '100%'; // Use full width when both sidebars are closed
    }
    if (isSidebarVisible && isRightSidebarVisible) {
      return `calc(100% - ${leftSidebarWidth}px - ${rightSidebarWidth}%)`;
    }
    if (isSidebarVisible) {
      return `calc(100% - ${leftSidebarWidth}px)`;
    }
    if (isRightSidebarVisible) {
      return `calc(100% - ${rightSidebarWidth}%)`;
    }
    return '100%'; // Default to full width
  };

  const presetQueries = [
    'Please be as specific as possible with your query.',
    'Please select words that are unambiguous.',
    'Recommend searching by study content instead of study author.',
    'Please click on the icon next to each sentence in the output to see the source document.',
    'Your query is not guaranteed to find the correct answer, but rather one that is semantically similar.',
    'Please click the dislike button to save the result of a poorly performing query for investigation.',
    'All data is stored on a database that will be vetted by IT before any official use.',
    'All current data in the knowledge center is publicly available.',
    'All data summarized by OpenAI may be used to train their AI models (is not the case when using the Enterprise API).',
    'Recommendations for (non-confidential) documents for inclusion should be sent to justin@aegentic.com.',
    'Pavonia does claim any ownership of any documents in the knowledge base.',
    'Uploaded documents will not be available until they have been vetted and processed.',
    'Please email Justin Yu (justin@aegentic.com) for any questions or concerns.'
  ];
  
  // const handleMore = (index) => {
  //   setIsMoreClicked(prevState => !prevState);
  //   // Add your logic for handling the 'more options' click
  //   console.log('More options clicked for message at index', index);
  // };  

  const handleMouseEnter = async (docId) => {
    if (activeTooltipId && activeTooltipId !== docId) {
      return;
    }
    
    setActiveTooltipId(docId);
    setHoveredDoc(docId);
    const originalId = Object.entries(idMapping)
      .find(([_, mappedId]) => mappedId === docId)?.[0];
  
    if (originalId) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/get_doc_info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ docId: originalId })
        });
  
        console.log('Response status:', response.status);
        
        if (!response.ok) {
          throw new Error('Failed to fetch tooltip data');
        }
  
        const data = await response.json();
        console.log('Response data:', data);
        
        if (data.status === 'success') {
          setTooltipContent(data);
        }
      } catch (error) {
        console.error('Error fetching tooltip data:', error);
      }
    }
  };

  const handleMouseLeave = () => {
    setActiveTooltipId(null);
    setHoveredDoc(null);
    setTooltipContent(null);
  };

  const toggleRightSidebar = () => {
    if (hasSystemOutput && !isNewChat) {
      setIsRightSidebarVisible(!isRightSidebarVisible);
    }
  };

  const handleReferenceUpdate = async (newReference) => {
    try {
      setIsLoading(true);
      
      const response = await fetch(`${API_BASE_URL}/api/update_reference`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filename: fileName,
          newReference: newReference // Use the passed newReference parameter
        })
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Failed to update reference');
      }
  
      if (data.status === 'success') {
        // Update the local state with the new reference
        setCurrentReference(newReference);
        setIsEditingRef(false);
        
        // Update conversation references if they exist for this filename
        setConversation(prev => prev.map(msg => {
          if (msg.from === 'bot' && msg.references) {
            const updatedReferences = { ...msg.references };
            Object.keys(updatedReferences).forEach(docId => {
              if (updatedReferences[docId] === currentReference) {
                updatedReferences[docId] = newReference;
              }
            });
            return { ...msg, references: updatedReferences };
          }
          return msg;
        }));
  
        console.log(`Reference updated successfully. Modified ${data.modified_count} documents.`);
      }
    } catch (error) {
      console.error('Error updating reference:', error);
      // Reset to the previous reference value on error
      setCurrentReference(currentReference);
    } finally {
      setIsLoading(false);
    }
  };

  const sendFeedback = async (feedback, userQuery, systemOutput) => {
    try {
      const response = await fetch(FEEDBACK_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_query: userQuery,
          system_output: systemOutput,
          feedback: feedback,
          userId: user?.sub
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      console.log('Feedback sent successfully');
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  // const [scrollPosition, setScrollPosition] = useState(0);
  // const sliderRef = useRef(null);

  // Utility function to normalize source IDs
  const normalizeSourceId = (id) => {
    if (!id) return null;
    const match = id.toString().match(/doc_\d+/);
    return match ? match[0] : null;
  };

  const handleDocumentClick = useCallback(async (uniqueId, idMapping, messageIndex) => {
    const normalizedId = normalizeSourceId(uniqueId);
    if (!normalizedId) {
        console.error(`Invalid document identifier: ${uniqueId}`);
        return;
    }

    setActiveDocRefs(prev => ({
        ...prev,
        [messageIndex]: normalizedId
    }));

    setActiveMessageIndex(messageIndex);
    setIsPopupOpen(false);
    setIsRightSidebarVisible(true);
    setIsLoading(true);
    setRefreshDownloadIcon(prev => !prev);

    setPdfError(null);
    setImageError(null);
    setServerError(null);
    setPdfBase64(null);
    setPdfImageUrl(null);
    setPdfDocument(null);

    try {
        console.log('Sending request with:', { uniqueId: normalizedId, idMapping });
        const response = await fetch(`${API_BASE_URL}/api/get_document_details`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uniqueId: normalizedId,
                idMapping: idMapping || {},
                isFilteredSearch,
                ...(isFilteredSearch && filteredFileName && {
                    filename_filter: filteredFileName.replace('.pdf', '')
                })
            })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Received response:', data);

        if (data.status === 'success') {
            if (!data.pdfBase64) {
                setPdfError('No PDF data received');
                return;
            }

            try {
                const binary = atob(data.pdfBase64);
                const array = new Uint8Array(binary.length);
                for (let i = 0; i < binary.length; i++) {
                    array[i] = binary.charCodeAt(i);
                }

                const loadingTask = pdfjsLib.getDocument({ data: array });
                const pdfDoc = await loadingTask.promise;
                setPdfDocument(pdfDoc);
            } catch (error) {
                console.error('PDF loading error:', error);
                setPdfError('Failed to load PDF document');
                return;
            }

            setActiveDocRefs(prev => ({
                ...prev,
                [messageIndex]: normalizedId
            }));

            await Promise.all([
                setPdfBase64(data.pdfBase64),
                setPdfImageUrl(data.imageUrl),
                setFileName(data.filename),
                setCurrentReference(data.reference),
                setCurrentPage(data.pageNumber),
                setTotalPages(data.totalPages),
                setCurrentIdMapping(idMapping || {}),
                setPdfPageNumber(data.pageNumber),
                setBboxPage(data.pageNumber)
            ]);
        } else {
            throw new Error(data.message || 'Unknown error occurred');
        }
    } catch (error) {
        console.error('Error retrieving document details:', error);
        setServerError(error.message);
    } finally {
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }
  }, [API_BASE_URL, isFilteredSearch, filteredFileName]);

  const handleDownload = () => {
    if (pdfBase64 && fileName) {
      const byteCharacters = atob(pdfBase64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  const toggleImageView = () => {
    setShowImage(!showImage);
    if (!showImage && !pdfImageUrl) {
      // If switching to image view and no image is loaded, fetch the current page image
      fetchPageImage(currentPage);
    }
  };

  // const renderMessageContent = (text) => {
  //   const parts = text.split(/(\(Source: [^\)]+\))/g);
  //   return parts.map((part, index) => {
  //     if (part.startsWith('(Source:')) {
  //       const sourceId = part.match(/\(Source: ([^\)]+)\)/)[1];
  //       return (
  //         <FileText
  //           key={index}
  //           size={16}
  //           onClick={() => handleDocumentClick(sourceId)}
  //           style={{
  //             color: activeDocumentId === sourceId ? '#FF79C6' : '#3498DB',
  //             cursor: 'pointer',
  //             marginLeft: '4px',
  //             marginRight: '4px',
  //             verticalAlign: 'middle'
  //           }}
  //         />
  //       );
  //     }
  //     return part;
  //   });
  // };

  // const getIconColor = (docId) => {
  //   const normalizedActiveId = normalizeSourceId(activeDocumentId);
  //   const normalizedDocId = normalizeSourceId(docId);
    
  //   return normalizedActiveId === normalizedDocId
  //     ? isDarkMode ? '#FF79C6' : '#bd93f9'
  //     : isDarkMode ? '#3498DB' : '#2980B9';
  // };

  const handlePageChange = async (direction) => {
    const newPage = currentPage + direction;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setPdfError(null);
      setImageError(null);
      
      try {
        const response = await fetch(`${API_BASE_URL}/api/get_page_image`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            uniqueId: activeDocumentId,
            idMapping: currentIdMapping || {}, // Add fallback empty object
            pageNumber: newPage,
            bboxPage: bboxPage,
            isFilteredSearch: isFilteredSearch, // Add this line
            ...(isFilteredSearch && filteredFileName && {
              filename_filter: filteredFileName.replace('.pdf', '')
            })
          }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        if (data.status === 'success') {
          setPdfImageUrl(data.imageUrl);
          setBboxPage(newPage);
        } else {
          throw new Error(data.message || 'Failed to fetch page image');
        }
      } catch (error) {
        console.error('Error fetching page image:', error);
        setImageError(error.message);
      }
    }
  };

  const fetchPageImage = async (pageNumber) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/get_page_image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          uniqueId: activeDocumentId, 
          idMapping: currentIdMapping,
          pageNumber: pageNumber,
          bboxPage: bboxPage
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.status === 'success') {
        setPdfImageUrl(data.imageUrl);
        setCurrentPage(pageNumber);
      } else {
        throw new Error(data.message || 'Failed to fetch page image');
      }
    } catch (error) {
      console.error('Error fetching page image:', error);
      setImageError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const MessageFormatter = ({
    text,
    idMapping,
    references,
    handleDocumentClick,
    isDarkMode,
    messageIndex,
    activeDocRefs = {}
  }) => {
    const [tooltipContent, setTooltipContent] = useState(null);
    const [hoveredDoc, setHoveredDoc] = useState(null);
  
    const normalizeId = (id) => {
      if (!id) return null;
      const match = id.toString().match(/doc_\d+/);
      return match ? match[0] : null;
    };
  
    const getIconColor = (docId, msgIndex) => {
      const normalizedDocId = normalizeId(docId);
      const activeDocId = activeDocRefs[msgIndex];
  
      if (normalizedDocId && activeDocId && normalizedDocId === activeDocId) {
        return isDarkMode ? '#FF79C6' : '#bd93f9';
      }
      return isDarkMode ? '#3498DB' : '#2980B9';
    };
  
    const handleMouseEnter = async (docId) => {
      setHoveredDoc(docId);
      const originalId = Object.entries(idMapping)
        .find(([_, mappedId]) => mappedId === docId)?.[0];
    
      if (originalId) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/get_doc_info`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ docId: originalId })
          });
    
          if (!response.ok) {
            throw new Error('Failed to fetch tooltip data');
          }
    
          const data = await response.json();
          if (data.status === 'success') {
            setTooltipContent(data);
          }
        } catch (error) {
          console.error('Error fetching tooltip data:', error);
        }
      }
    };
  
    const handleMouseLeave = () => {
      setHoveredDoc(null);
      setTooltipContent(null);
    };
  
    const processSourceCitations = (content) => {
      return content.split(/(\([Ss]ources?:(?:[^()]*(?:docs?_\d+|\d+)[^()]*)\))/g).map((part, index) => {
        if (!part) return null;
        
        const sourceMatch = part.match(/\([Ss]ources?:(.*?)\)/);
        if (sourceMatch) {
          // Extract and normalize document IDs
          const docIds = sourceMatch[1].split(',').map(id => {
            // Clean up whitespace
            id = id.trim();
            // Handle standalone numbers
            if (/^\d+$/.test(id)) {
              return `doc_${id}`;
            }
            // Handle docs_X format
            if (id.startsWith('docs_')) {
              return id.replace('docs_', 'doc_');
            }
            // Handle doc_X format (already correct)
            if (id.startsWith('doc_')) {
              return id;
            }
            return null;
          }).filter(Boolean); // Remove any null values
    
          return (
            <span key={index} className="file-wrapper">
              {docIds.map((docId, idx) => (
                <div
                  key={`${docId}-${idx}`}
                  style={{ 
                    position: 'relative',
                    display: 'inline-block'
                  }}
                >
                  <FileText
                    size={16}
                    className="file-icon"
                    onClick={() => handleDocumentClick(docId, idMapping, messageIndex)}
                    onMouseEnter={() => handleMouseEnter(docId)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      cursor: "pointer",
                      color: getIconColor(docId, messageIndex),
                      transition: 'color 0.3s ease',
                      verticalAlign: 'middle',
                      marginLeft: '4px',
                      marginRight: '4px'
                    }}
                  />
                  {hoveredDoc === docId && tooltipContent && (
                    <div
                      onMouseLeave={handleMouseLeave}
                      style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: isDarkMode ? '#2C2C2C' : '#FFFFFF',
                        border: `2px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
                        borderRadius: '8px',
                        padding: '12px',
                        zIndex: 1000,
                        minWidth: '300px',
                        maxWidth: '500px',
                        boxShadow: `0 4px 12px ${isDarkMode ? 'rgba(255, 121, 198, 0.2)' : 'rgba(189, 147, 249, 0.2)'}`,
                        color: isDarkMode ? '#E0E0E0' : '#333333',
                        fontSize: '14px',
                        whiteSpace: 'normal',
                        textAlign: 'left',
                        pointerEvents: 'auto'
                      }}
                    >
                      {/* Tooltip content remains the same */}
                      <div 
                        style={{ 
                          fontWeight: 'bold',
                          fontSize: '14px',
                          marginBottom: '12px',
                          borderBottom: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
                          paddingBottom: '8px',
                          color: isDarkMode ? '#FF79C6' : '#bd93f9'
                        }}
                      >
                        {tooltipContent.filename}
                      </div>
                      {tooltipContent.reference && (
                        <div 
                          style={{ 
                            marginBottom: '12px',
                            fontSize: '14px',
                            color: isDarkMode ? '#cccccc' : '#666666'
                          }}
                        >
                          <span style={{ fontWeight: '500' }}>Reference:</span> {tooltipContent.reference}
                        </div>
                      )}
                      <div 
                        style={{ 
                          fontSize: '14px',
                          lineHeight: '1.5',
                          maxHeight: '600px',
                          overflowY: 'auto'
                        }}
                      >
                        {tooltipContent.text_representation}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </span>
          );
        }
        return part;
      });
    };
  
    if (!text || text.trim().toLowerCase() === "no results found.") {
      return <p>{text}</p>;
    }
  
    const preprocessText = (text) => {
      return text
        .replace(/\r\n/g, '\n')
        .replace(/\n+(\d+\.)\s+(?![.\d])/g, '\n$1 ')
        .replace(/\n+(\d+\.\d+)\s+/g, '\n\n$1 ')
        .replace(/\n{3,}/g, '\n\n')
        .trim();
    };
  
    const customRenderer = {
      p: ({ children }) => {
        const processedChildren = React.Children.map(children, child => {
          if (typeof child === 'string') {
            const mainSectionMatch = child.match(/^(\d+\.)\s+(?![.\d])(.*)$/);
            if (mainSectionMatch) {
              return (
                <>
                  <span style={{ fontWeight: 'bold' }}>{mainSectionMatch[1]}</span>{' '}
                  {processSourceCitations(mainSectionMatch[2])}
                </>
              );
            }
            
            const subsectionMatch = child.match(/^(\d+\.\d+)\s+(.*)$/);
            if (subsectionMatch) {
              return (
                <>
                  <span style={{ fontWeight: 'bold' }}>{subsectionMatch[1]}</span>{' '}
                  {processSourceCitations(subsectionMatch[2])}
                </>
              );
            }
            
            return processSourceCitations(child);
          }
          return child;
        });
        return <p style={{ marginBottom: '1rem' }}>{processedChildren}</p>;
      },
      
      ul: ({ children }) => (
        <ul style={{ 
          listStyleType: 'disc',
          paddingLeft: '1.5rem',
          marginBottom: '1rem',
          lineHeight: '1.5'
        }}>
          {children}
        </ul>
      ),
      
      li: ({ children }) => (
        <li style={{ marginBottom: '0.5rem' }}>
          {React.Children.map(children, child => {
            if (typeof child === 'string') {
              return processSourceCitations(child);
            }
            return child;
          })}
        </li>
      )
    };
  
    const processedText = preprocessText(text);
  
    return (
      <div className="markdown-content">
        <ReactMarkdown components={customRenderer}>{processedText}</ReactMarkdown>
      </div>
    );
  };
  
  // const formatMessageWithCitations = (text, idMapping) => {
  //   if (!text || text.trim().toLowerCase() === "No results found.") {
  //     return <p>{text}</p>;
  //   }
  
  //   const paragraphs = text.split('\n\n');
    
  //   return paragraphs.map((paragraph, pIndex) => {
  //     const parts = paragraph.split(/(\([Ss]ource: [^)]+\))/g);
  
  //     return (
  //       <p key={pIndex} style={{ marginBottom: '1em' }}>
  //         {parts.map((part, partIndex) => {
  //           if (part.startsWith('(Source:') || part.startsWith('(source:')) {
  //             // Extract all doc IDs using a comprehensive regex
  //             const docIds = [...part.matchAll(/doc_\d+/g)]
  //               .map(match => match[0])
  //               .filter(Boolean);
  
  //             return (
  //               <span key={partIndex} style={{ 
  //                 display: 'inline',
  //                 marginLeft: '1px',
  //                 marginRight: '1px'
  //               }}>
  //                 {docIds.map((docId, idx) => (
  //                   <React.Fragment key={`${docId}-${idx}`}>
  //                     <FileText
  //                       size={16}
  //                       onClick={(event) => handleDocumentClick(docId, idMapping, event)}
  //                       style={{
  //                         color: getIconColor(docId),
  //                         cursor: 'pointer',
  //                         marginLeft: '0px',
  //                         marginRight: '0px',
  //                         verticalAlign: 'middle',
  //                         display: 'inline-block',
  //                         position: 'relative',
  //                         top: '-1px' // Slight vertical adjustment to better align with text
  //                       }}
  //                       title={`View document ${docId}`}
  //                     />
  //                     {idx < docIds.length - 1 && <span style={{ margin: '0 1px' }}/>}
  //                   </React.Fragment>
  //                 ))}
  //               </span>
  //             );
  //           }
  //           return part;
  //         })}
  //       </p>
  //     );
  //   });
  // };

  // const extractDocIds = (sourceText) => {
  //   const docIds = [];
  //   const regex = /doc_\d+/g;
  //   let match;
    
  //   while ((match = regex.exec(sourceText)) !== null) {
  //     docIds.push(match[0]);
  //   }
    
  //   return docIds;
  // };

  // const handlePrevQueries = () => {
  //   setCurrentQueryIndex((prevIndex) => 
  //     (prevIndex - 1 + presetQueries.length) % presetQueries.length
  //   );
  // };

  // const handleNextQueries = () => {
  //   setCurrentQueryIndex((prevIndex) => 
  //     (prevIndex + 1) % presetQueries.length
  //   );
  // };

  const visibleBoxes = 3;
  const boxWidth = 180; // Width of each box including margin

  const handleScroll = (direction) => {
    setCurrentQueryIndex((prevIndex) => {
      const newIndex = (prevIndex + direction + presetQueries.length) % presetQueries.length;
      return newIndex;
    });
  };

  const getVisibleQueries = () => {
    const queries = [];
    for (let i = 0; i < visibleBoxes; i++) {
      const index = (currentQueryIndex + i) % presetQueries.length;
      queries.push(presetQueries[index]);
    }
    return queries;
  };

  // // Function to get the circular index
  // const getCircularIndex = (index) => {
  //   return (index + presetQueries.length) % presetQueries.length;
  // };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  // const handleFileChange = (event) => {
  //   setFile(event.target.files[0]);
  // };

  // const handleMessageChange = (event) => {
  //   setMessage(event.target.value);
  // };

  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter' && !event.shiftKey) {
  //     event.preventDefault();
  //     sendMessage();
  //   } else if (event.key === 'ArrowUp') {
  //     event.preventDefault();
  //     sendMessage();
  //   }
  // };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    // Clear icon states when starting a new chat
    if (conversation.length === 0) {
      setMessageIconStates({});
    }
  }, [conversation.length]);

  useEffect(() => {
    // Create SVG favicon
    const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#BD93F9" stroke="#BD93F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M12 2L15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2z"/>
      </svg>
    `;
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    // Set favicon
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = url;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = url;
      document.head.appendChild(newLink);
    }

    // Clean up
    return () => URL.revokeObjectURL(url);
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);

  const scrollToBottom = () => {
    if (conversationRef.current) {
      const scrollHeight = conversationRef.current.scrollHeight;
      const height = conversationRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      conversationRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]); // This will trigger scrollToBottom whenever the conversation updates

  useEffect(() => {
    // Initial fetch when component mounts and user is already authenticated
    if (isAuthenticated && user?.sub) {
      fetchRecentChats();
    }
  }, []); // This runs once on mount

  useEffect(() => {
    // Fetch whenever auth state or user ID changes
    if (isAuthenticated && user?.sub) {
      fetchRecentChats();
    } else {
      // Clear recent chats if user is not authenticated
      setRecentChats([]);
    }
  }, [isAuthenticated, user?.sub]); // This runs when auth state or user ID changes

  const fetchRecentChats = async () => {
    if (!user?.sub) {
      console.log('No user ID available for fetching recent chats');
      return;
    }
  
    try {
      const response = await fetch(`${RECENT_CHATS_ENDPOINT}?userId=${encodeURIComponent(user.sub)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        setRecentChats(data);
      } else {
        console.error("Received non-array data for recent chats:", data);
        setRecentChats([]);
      }
    } catch (error) {
      console.error("Error fetching recent chats:", error);
      setRecentChats([]);
    }
  };

  const handleRecentChatClick = (query) => {
    setMessage(query);
    sendMessageStream(query);
  };

  const startNewChat = () => {
    setConversation([]);
    setHasSystemOutput(false);
    setIsRightSidebarVisible(false);
    setIsNewChat(true);
    setPdfImage(null);
  };

  const handleHelpCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        addToClipboard(text);
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };

  // const sendMessage = async (userMessage) => {
  //   const messageToSend = userMessage || message;
    
  //   if (isQueryPending || messageToSend.trim() === "") return;
        
  //   setIsQueryPending(true);
  //   setMessage("");
  //   setIsNewChat(false);
  //   setIsWaitingForResponse(true);
    
  //   setConversation(prev => [...prev, { text: messageToSend, from: "user" }]);
    
  //   try {
  //     const controller = new AbortController();
  //     setCancelToken(controller);
      
  //     const endpoint = isFilteredSearch ? `${API_BASE_URL}/api/query_prefilter` : API_ENDPOINT;
  //     const requestBody = {
  //       query: messageToSend,
  //       userId: user?.sub,
  //       kLimit: kLimit,
  //       ...(isFilteredSearch && filteredFileName && {
  //         filename_filter: filteredFileName.replace('.pdf', '')
  //       })
  //     };
  
  //     console.log('Request body:', requestBody); // Add debugging
      
  //     const response = await fetch(endpoint, {
  //       method: 'POST',
  //       headers: { 
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(requestBody),
  //       signal: controller.signal,
  //     });
      
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
  //     }
      
  //     const data = await response.json();
      
  //     setConversation(prev => [...prev, { 
  //       text: data.response, 
  //       from: "bot",
  //       idMapping: data.idMapping,
  //       references: data.references
  //     }]);
      
  //     setHasSystemOutput(true);
  //     setContextAlt(data.context_alt);
      
  //     await fetchRecentChats();
  //   } catch (error) {
  //     console.error("Error:", error);
  //     if (error.name === 'AbortError') {
  //       console.log('Query was cancelled');
  //     } else {
  //       const errorMessage = `Error: ${error.message}`;
  //       setConversation(prev => [...prev, { text: errorMessage, from: "bot" }]);
  //       setHasSystemOutput(true);
  //     }
  //   } finally {
  //     setIsQueryPending(false);
  //     setCancelToken(null);
  //     setIsWaitingForResponse(false);
  //   }
  // };

  const sendMessageStream = async (userMessage, isHelpQuestion = false) => {
    const messageToSend = userMessage || message;
    if (isQueryPending || messageToSend.trim() === "") return;
  
    setIsQueryPending(true);
    setMessage("");
    setIsNewChat(false);
    setIsWaitingForResponse(true);
  
    // Add user's message to the conversation
    setConversation(prev => [
      ...prev, 
      { 
        text: messageToSend, 
        from: "user" 
      }
    ]);
  
    try {
      const controller = new AbortController();
      setCancelToken(controller);
  
      // Decide which endpoint you use
      const endpoint = isFilteredSearch
        ? `${API_BASE_URL}/api/query_prefilter`
        : `${API_BASE_URL}/api/query`;
  
      const requestBody = {
        query: messageToSend,
        userId: user?.sub,
        kLimit,
        ...(isFilteredSearch && filteredFileName && {
          filename_filter: filteredFileName.replace('.pdf', '')
        })
      };
  
      // Send request, parse JSON
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        signal: controller.signal
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      // data might be:
      // {
      //   all_subqueries: [...],
      //   results: [
      //     {
      //       subquery: "...",
      //       system_response: "...",
      //       id_mapping: {...},
      //       references: {...},
      //       ...
      //     },
      //     ...
      //   ],
      //   combined_search_time: ...
      // }
  
      if (data.results && Array.isArray(data.results)) {
        // For each subquery response, add a system message
        data.results.forEach((subqObj) => {
          const newSystemMsg = {
            text: subqObj.system_response,
            from: "bot",
            idMapping: subqObj.id_mapping || {},
            references: subqObj.references || {},
            context_alt: subqObj.context_alt || "",  // <-- store subquery context
            parentUserQuery: messageToSend,  
              // ^--- KEY: store the entire user query that produced this system output
          };
          setConversation(prev => [...prev, newSystemMsg]);
        });
      }
  
      setHasSystemOutput(true);
      // refresh the “recent chats”
      await fetchRecentChats();
  
    } catch (error) {
      console.error("Error in sendMessageStream:", error);
      if (error.name !== 'AbortError') {
        // Add a single system message with an error
        setConversation(prev => [
          ...prev,
          { 
            text: `Error: ${error.message}`, 
            from: "bot" 
          }
        ]);
      }
    } finally {
      setIsQueryPending(false);
      setCancelToken(null);
      setIsWaitingForResponse(false);
    }
  };

  const cancelQuery = () => {
    if (cancelToken) {
      cancelToken.abort();
      setIsQueryPending(false);
      setCancelToken(null);
    }
  };

  const SystemMessage = ({ 
    message,
    handleDocumentClick,
    idMapping,
    references,
    messageIndex,
    activeDocRefs
  }) => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '5px',
      }}>
        <div style={{
          flexShrink: 0,
          marginRight: '10px',
          marginTop: '5px',
        }}>
          <Sparkle size={30} style={{ color: isDarkMode ? '#FF79C6' : '#BD93F9' }} />
        </div>
        <div style={{
          ...styles.botMessage,
          flex: 1,
        }}>
          <MessageFormatter
            text={message}
            idMapping={idMapping}
            references={references}
            handleDocumentClick={handleDocumentClick}
            isDarkMode={isDarkMode}
            messageIndex={messageIndex}
            activeDocRefs={activeDocRefs}
            styles={styles}
          />
        </div>
      </div>
    );
  };

  const regenerateResponse = async (userMessage, index) => {
    setIsWaitingForResponse(true);
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: userMessage }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      // Update only the bot's response in the conversation
      setConversation(prev => {
        const newConversation = [...prev];
        newConversation[index] = { text: data.response, from: "bot" };
        return newConversation;
      });

      setIdMapping(data.idMapping);
      setContextAlt(data.context_alt);
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = `Error: ${error.message}`;
      setConversation(prev => {
        const newConversation = [...prev];
        newConversation[index] = { text: errorMessage, from: "bot" };
        return newConversation;
      });
    } finally {
      setIsWaitingForResponse(false);
    }
  };

  const generateFollowUpQuestions = async () => {
    setIsHelpLoading(true);
    setIsHelpPopupOpen(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/generate_questions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ context: contextAlt }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Remove any numbering from the beginning of the questions
      const cleanedQuestions = data.questions.map(question => 
        question.replace(/^\d+\.\s*/, '').trim()
      );
      setHelpPopupContent(cleanedQuestions);
    } catch (error) {
      console.error("Error generating follow-up questions:", error);
      setHelpPopupContent(["Error generating questions. Please try again."]);
    } finally {
      setIsHelpLoading(false);
    }
  };

  // somewhere in App.js or your main conversation logic file

  const handleActionButton = (action, index) => {
    // Mark the icon as clicked (for short highlight effect)
    setClickedIcons(prev => ({
      ...prev,
      [`${action}-${index}`]: true
    }));

    // Grab the conversation item for the message at `index`
    const cItem = conversation[index];

    switch (action) {

      case 'regenerate':
      case 'resubmit': {
        // We want to re-run the user query that led to this system message
        if (cItem && cItem.parentUserQuery) {
          sendMessageStream(cItem.parentUserQuery);
        }
        break;
      }

      case 'help': {
        generateFollowUpQuestions();
        break;
      }

      case 'more':
        // open the context popup
        setIsPopupOpen(true);
        // set the local context from conversation[index].context_alt
        setContextAlt(conversation[index].context_alt || "");
        // set the local systemResponse from conversation[index].text
        setSystemResponseForContext(conversation[index].text || "");
        // also set currentIdMapping, references, etc.
        setCurrentIdMapping(conversation[index].idMapping || {});
        break;

      case 'like': {
        // user clicked thumbs up
        console.log(`Liked message at index ${index}`);
        // we assume cItem.from === 'bot'
        // so cItem.text is system response, and cItem references the parent user message
        const userText = conversation[index - 1]?.text || ""; 
        sendFeedback('good', userText, cItem.text);
        break;
      }

      case 'dislike': {
        // user clicked thumbs down
        console.log(`Disliked message at index ${index}`);
        const userText = conversation[index - 1]?.text || "";
        sendFeedback('bad', userText, cItem.text);
        break;
      }

      case 'copy': {
        // 1) Grab the system text and references
        const messageText = cItem.text;
        const references = cItem.references || {};
      
        // 2) Replace source citations that might contain multiple document IDs
        let modifiedText = messageText.replace(
          /\(Source: ((?:doc_\d+(?:,\s*doc_\d+)*)+)\)/g,
          (match, docIds) => {
            // Split the document IDs and trim any whitespace
            const docIdArray = docIds.split(',').map(id => id.trim());
            
            // Map each document ID to its reference (if available)
            const referenceStrings = docIdArray.map(docId => {
              const referenceVal = references[docId];
              return referenceVal && referenceVal.trim() !== '' ? referenceVal : docId;
            });
      
            // Join the references with commas
            const combinedReferences = referenceStrings.join(', ');
            
            // Return the formatted citation
            return `(${combinedReferences})`;
          }
        );
      
        // 3) Copy to clipboard and add to clipboard history
        navigator.clipboard.writeText(modifiedText)
          .then(() => {
            addToClipboard(modifiedText);
            console.log('Text copied to clipboard');
          })
          .catch(err => console.error('Failed to copy text: ', err));
        break;
      }

      default:
        break;
    }

    // short highlight effect reset
    setTimeout(() => {
      setClickedIcons(prev => ({
        ...prev,
        [`${action}-${index}`]: false
      }));
    }, 300);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleLike = (index) => {
    console.log(`Liked message at index ${index}`);
  };

  const handleDislike = (index) => {
    console.log(`Disliked message at index ${index}`);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => console.log('Text copied to clipboard'))
      .catch(err => console.error('Failed to copy text: ', err));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isSettingsOpen &&
          settingsMenuRef.current &&
          !settingsMenuRef.current.contains(event.target) &&
          !settingsButtonRef.current.contains(event.target)) {
        setIsSettingsOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSettingsOpen]);

  const handleRegenerate = (index) => {
    console.log(`Regenerating message at index ${index}`);
  };

  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handlePopupClose = useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  const handlePaperclipClick = () => {
    fileInputRef.current.click();
  };

  // Dynamically generate styles based on the current theme
  const getStyles = (isDark) => ({
    '@import': "url('https://fonts.googleapis.com/css2?family=Aptos:wght@400;700&display=swap')",
    '@global': {
      'body': {
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      },
      '#root': {
        height: '100vh',
        width: '100vw',
      },
    },
    markdownStyles: {
      '.markdown-content': {
        fontFamily: "'Aptos', sans-serif",
        lineHeight: '1.4'
      },
      '.markdown-content h1': {
        fontSize: '1.4em',
        marginTop: '0.8em',
        marginBottom: '0.4em',
        fontWeight: 600,
        lineHeight: 1.2
      },
      '.markdown-content h2': {
        fontSize: '1.2em',
        marginTop: '0.7em',
        marginBottom: '0.3em',
        fontWeight: 600,
        lineHeight: 1.2
      },
      '.markdown-content h3': {
        fontSize: '1.1em',
        marginTop: '0.6em',
        marginBottom: '0.3em',
        fontWeight: 600,
        lineHeight: 1.2
      },
      '.markdown-content p': {
        marginBottom: '0.6em',
        lineHeight: 1.4
      },
      '.markdown-content ul, .markdown-content ol': {
        marginTop: '0.3em',
        marginBottom: '0.6em',
        paddingLeft: '1.5em'
      },
      '.markdown-content li': {
        marginBottom: '0.3em',
        lineHeight: 1.4
      },
      '.markdown-content li p': {
        marginBottom: '0.3em'
      },
      '.markdown-content li + li': {
        marginTop: '0.1em'
      },
      '.markdown-content li > ul, .markdown-content li > ol': {
        marginTop: '0.2em',
        marginBottom: '0.2em'
      },
      '.markdown-content ol': {
        listStyleType: 'decimal',
        counterReset: 'item'
      },
      '.markdown-content ol > li': {
        display: 'block'
      },
      '.markdown-content ol > li:before': {
        content: 'counter(item) ". "',
        counterIncrement: 'item',
        marginRight: '0.5em'
      }
    },
    container: {
      display: 'flex',
      height: '100vh',
      width: '100vw',
      color: isDark ? '#E0E0E0' : '#333333',
      backgroundColor: isDark ? '#1E1E1E' : '#FFFFFF',
      fontFamily: "'Aptos', sans-serif",
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
    },  
    sidebar: {
      width: '260px',
      height: '100%',
      paddingTop: '60px',
      paddingLeft: '20px',
      paddingRight: '20px',
      backgroundColor: isDark ? '#252525' : '#F0F0F0',
      overflowY: 'auto',
      transition: 'transform 0.3s ease-in-out',
      transform: isSidebarVisible ? 'translateX(0)' : 'translateX(-100%)',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 10,
      boxSizing: 'border-box',
    },
    // mainContentWrapper: {
    //   flex: 1,
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   height: '100%',
    //   transition: 'padding-left 0.3s ease-in-out',
    //   paddingLeft: isSidebarVisible ? '300px' : '0',
    // },
    mainContentWrapper: {
      display: 'flex',
      flex: 1,
      height: '100%',
      transition: 'margin-left 0.3s ease-in-out, margin-right 0.3s ease-in-out, width 0.3s ease-in-out',
      marginLeft: isSidebarVisible ? `${leftSidebarWidth}px` : '0',
      marginRight: isRightSidebarVisible ? `${rightSidebarWidth}%` : '0',
      width: calculateCenterAreaWidth(),
      justifyContent: 'center', // Center the chat column
    },
    chatColumn: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      maxWidth: '700px',
      width: !isSidebarVisible && !isRightSidebarVisible ? '50%' : '100%', // Adjust width to 50% when both sidebars are closed
      margin: '0 auto',
      padding: '0 20px',
      boxSizing: 'border-box',
      transition: 'width 0.3s ease-in-out, margin-left 0.3s ease-in-out',
    },
    leftSidebar: {
      width: `${leftSidebarWidth}px`,
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      transition: 'transform 0.3s ease-in-out',
      transform: isSidebarVisible ? 'translateX(0)' : `translateX(-${leftSidebarWidth}px)`,
      // ... other left sidebar styles
    },
    rightSidebar: {
      width: '40%',
      height: '100%',
      backgroundColor: isDark ? '#252525' : '#F0F0F0',
      position: 'fixed',
      top: 0,
      right: isRightSidebarVisible ? 0 : '-40%',
      transition: 'right 0.3s ease-in-out',
      overflowY: 'auto',
      boxShadow: isDark ? '-2px 0 5px rgba(0,0,0,0.5)' : '-2px 0 5px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px', // Reduced top padding
    },
    pdfViewerColumn: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      boxSizing: 'border-box',
      height: '100%',
    },
    pdfContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '97%',
      width: '98%',
      padding: '20px',
      boxSizing: 'border-box',
    },
    downloadIconContainer: {
      alignSelf: 'flex-start',
      marginTop: '-55px',
      marginBottom: '10px',
    },
    // downloadIcon: {
    //   color: isDark ? '#E0E0E0' : '#333333',
    //   cursor: 'pointer',
    //   transition: 'color 0.3s',
    //   '&:hover': {
    //     color: isDark ? '#3498DB' : '#2980B9',
    //   },
    // },
    downloadIconLeft: {
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      transition: 'color 0.3s',
      leftMargin: '-200px',
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
    },
    downloadIconRight: {
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      transition: 'color 0.3s',
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
    },
    downloadIcon2: {
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      marginRight: '290px',
      transition: 'color 0.3s',
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
    },
    pdfViewer: {
      width: '100%',
      flexGrow: 1,
      border: 'none',
      marginBottom: '10px', // Add space below the PDF viewer
    },
    pdfInfo: {
      marginTop: 'auto', // Push to the bottom of the container
      textAlign: 'center',
      padding: '10px 0',
    },
    pdfImage: {
      maxWidth: '100%',
      maxHeight: '90vh',
      objectFit: 'contain',
      marginTop: '0px',
      marginBottom: '-3px',
    },
    mainContent: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      boxSizing: 'border-box',
    },
    sidebarToggle: {
      position: 'absolute',
      left: isSidebarVisible ? '260px' : '0',
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      border: 'none',
      padding: '5px', // Reduced padding to make the button smaller
      cursor: 'pointer',
      transition: 'left 0.3s ease-in-out',
      zIndex: 20,
      borderRadius: '10%', // Make the button circular
      width: '24px', // Set a fixed width
      height: '24px', // Set a fixed height
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sidebarTitle: {
      fontSize: '1.8em',
      fontWeight: 'bold',
      marginTop: '30px',
      marginBottom: '30px',
      color: isDark? '#FF79C6' : '#bd93f9',
    },
    sidebarTitle2: {
      fontSize: '1em',
      fontWeight: 'bold',
      marginBottom: '60px',
      color: isDark ? '#3498DB' : '#2980B9',
    },
    sidebarSection: {
      marginBottom: '20px',
      paddingRight: '15px',
    },
    sidebarSectionTitle: {
      fontSize: '1.0em',
      color: isDark ? '#BDBDBD' : '#666666',
      marginBottom: '10px',
    },
    sidebarItem: {
      fontSize: '0.9em',
      color: isDark ? '#9E9E9E' : '#333333',
      marginBottom: '5px',
      cursor: 'pointer',
      transition: 'color 0.3s',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      paddingRight: '15px',
      boxSizing: 'border-box',
      '&:hover': {
        color: isDark ? '#E0E0E0' : '#000000',
      },
    },
    rightSidebar: {
      width: '40%',
      height: '100%',
      backgroundColor: isDark ? '#252525' : '#F0F0F0',
      position: 'fixed',
      top: 0,
      right: isRightSidebarVisible ? 0 : '-40%',
      transition: 'right 0.3s ease-in-out',
      overflowY: 'auto',
      boxShadow: isDark ? '-2px 0 5px rgba(0,0,0,0.5)' : '-2px 0 5px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '60px', // Add padding to avoid overlapping with the settings icon
    },
    rightSidebarToggle: {
      position: 'fixed',
      right: isRightSidebarVisible ? '40%' : '0',
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      border: 'none',
      padding: '5px',
      cursor: 'pointer',
      transition: 'right 0.3s ease-in-out',
      zIndex: 1001,
      borderRadius: '10%',
      width: '24px',
      height: '24px',
      display: hasSystemOutput && !isNewChat ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pdfImageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px 0',
    },
    actionButton: (isClicked) => ({
      backgroundColor: isClicked ? '#3498DB' : 'transparent',
      border: 'none',
      color: isClicked ? 'white' : (isDark ? '#9E9E9E' : '#666666'),
      cursor: 'pointer',
      marginLeft: '10px',
      padding: '5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#E0E0E0',
        color: isDark ? '#E0E0E0' : '#333333',
      },
    }),
    addWorkspaceButton: {
      fontSize: '1em',
      color: isDark ? '#9E9E9E' : '#333333',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: '5px 10px',
      borderRadius: '5px',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#333333' : '#E0E0E0',
      },
    },
    questionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '5px',
    },
    questionActions: {
      display: 'flex',
      gap: '10px',
    },
    actionButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: isDark ? '#9E9E9E' : '#666666',
      padding: '5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#E0E0E0',
        color: isDark ? '#E0E0E0' : '#333333',
      },
    },
    uploadIcon: {
      marginBottom: '30px',
      color: isDark ? '#3498DB' : '#2980B9',
    },
    loadingMessage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      fontSize: '1.2em',
      color: isDark ? '#E0E0E0' : '#333333',
    },
    queryGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '15px',
      marginBottom: '30px',
      width: '100%',
      maxWidth: '700px',
    },
    queryButton: {
      backgroundColor: isDark ? '#2C2C2C' : '#E0E0E0',
      border: `1px solid ${isDark ? '#3A3A3A' : '#CCCCCC'}`,
      color: isDark ? '#E0E0E0' : '#333333',
      padding: '12px',
      borderRadius: '12px',
      textAlign: 'left',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#D0D0D0',
      },
    },
    paperclipButton: {
      position: 'absolute',
      left: '5px',
      bottom: '8px',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: isDark ? '#c5c5d2' : '#6e6e80',
      padding: '8px', // Add some padding for easier clicking
    },
    warningMessage: {
      textAlign: 'center',
      fontSize: '0.8em',
      color: isDark ? '#9E9E9E' : '#666666',
      marginTop: '10px',
    },
    warningMessage2: {
      textAlign: 'center',
      fontSize: '1.0em',
      color: isDark ? '#9E9E9E' : '#666666',
      marginTop: '10px',
      marginBottom: '10px',
    },
    warningMessage3: {
      textAlign: 'center',
      fontSize: '1.0em',
      color: isDark ? '#9E9E9E' : '#666666',
      marginTop: '20px',
      marginBottom: '10px',
    },
    draggingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(16, 163, 127, 0.1)',
      borderRadius: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.2em',
      color: '#10a37f',
      pointerEvents: 'none',
    },
    fileInputContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    fileInput: {
      display: 'none',
    },
    fileInputLabel: {
      backgroundColor: isDark ? '#2C2C2C' : '#E0E0E0',
      color: isDark ? '#E0E0E0' : '#333333',
      padding: '10px 15px',
      borderRadius: '5px 0 0 5px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#D0D0D0',
      },
    },
    uploadButton: {
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      border: 'none',
      padding: '10px 15px',
      borderRadius: '0 5px 5px 0',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#2980B9' : '#3498DB',
      },
    },
    conversation: {
      flex: 1,
      overflowY: 'auto',
      padding: '20px',
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      scrollBehavior: 'smooth', // Add smooth scrolling
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '15px',
      maxWidth: '70%',
    },
    userMessage: {
      alignSelf: 'flex-end',
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      padding: '10px 15px',
      borderRadius: '15px 15px 0 15px',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      maxWidth: '100%', // Limit the width to 70% of the container
      overflowWrap: 'break-word', // Ensure long words are broken
      hyphens: 'auto', // Enable hyphenation for very long words
      maxHeight: '300px', // Set a maximum height
      overflowY: 'auto', // Add vertical scrolling if content exceeds max height
    },
    botMessage: {
      alignSelf: 'flex-start',
      backgroundColor: isDark ? '#2C2C2C' : '#E0E0E0',
      color: isDark ? '#E0E0E0' : '#333333',
      padding: '18px 18px',  // Changed from 0px to 24px vertical padding
      borderRadius: '15px 15px 15px 0',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap'
    },
    inputArea: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 20px 20px',
      position: 'relative',
    },
    inputWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      flex: 1,
      padding: '16px',
      paddingLeft: '25px', // Make room for the paperclip button
      paddingRight: '50px', // Make room for the send button
      backgroundColor: isDark ? '#2C2C2C' : '#F0F0F0',
      color: isDark ? '#E0E0E0' : '#333333',
      border: '1px solid ' + (isDark ? '#3A3A3A' : '#CCCCCC'),
      borderRadius: '24px', // Rounded corners on all sides
      fontSize: '1em',
      '&:focus': {
        outline: 'none',
        boxShadow: `0 0 0 2px ${isDark ? '#3498DB' : '#2980B9'}`,
      },
    },
    sendButton: {
      position: 'absolute',
      right: '14px',
      bottom: '9px',
      width: '32px',
      height: '32px',
      backgroundColor: isQueryPending ? (isDark ? '#FF79C6' : '#bd93f9') : (isDark ? '#3498DB' : '#2980B9'),
      color: 'white',
      border: 'none',
      borderRadius: isQueryPending ? '12px' : '50%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'background-color 0.3s, border-radius 0.3s',
      '&:hover': {
        backgroundColor: isQueryPending ? (isDark ? '#FF79C6' : '#bd93f9') : (isDark ? '#2980B9' : '#3498DB'),
      },
    },
    settingsButton: {
      position: 'absolute',
      top: '20px',
      right: '30px',
      backgroundColor: 'transparent',
      border: 'none',
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
    },
    settingsMenu: {
      position: 'absolute',
      top: '60px',
      right: '20px',
      backgroundColor: isDark ? '#252525' : '#F0F0F0',
      border: `1px solid ${isDark ? '#3A3A3A' : '#CCCCCC'}`,
      borderRadius: '16px',
      padding: '10px',
      zIndex: 1000,
    },
    settingsItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#E0E0E0',
      },
    },
    settingsItemText: {
      marginLeft: '10px',
    },
    newChatButton: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      width: '30px',
      height: '30px',
      backgroundColor: isDark ? '#3498DB' : '#2980B9',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'background-color 0.3s',
      zIndex: 1000,
      '&:hover': {
        backgroundColor: isDark ? '#2980B9' : '#3498DB',
      },
    },
    newChatButtonHidden: {
      position: 'absolute',
      left: '20px',
      top: '20px',
      width: '30px',
      height: '30px',
    },
    newChatButtonText: {
      marginLeft: '5px',
    },
    sidebarHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    messageActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '5px',
    },
    loadingIndicatorContainer: {
      position: 'absolute',
      left: '30px', // Align with the left side of the input box
      bottom: '100%', // Position it just above the input area
      marginBottom: '10px', // Add some margin between the indicator and the input area
    },
    loadingSpinner: {
      width: '30px', // Increase the width
      height: '30px', // Increase the height
      border: `3px solid ${isDark ? '#3498DB' : '#2980B9'}`,
      borderTop: '3px solid transparent',
      borderRadius: '50%',
      animation: 'spin 1s linear infinite',
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    },
    actionButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: isDark ? '#9E9E9E' : '#666666',
      cursor: 'pointer',
      marginLeft: '10px',
      padding: '5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#E0E0E0',
        color: isDark ? '#E0E0E0' : '#333333',
      },
    },
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      minHeight: '150px', // Ensure a minimum height for the loading content
    },
    // loadingSpinner: {
    //   animation: 'spin 1s linear infinite',
    //   marginBottom: '15px',
    //   color: isDark ? '#E0E0E0' : '#333333',
    // },
    loadingText: {
      textAlign: 'center',
      color: isDark ? '#E0E0E0' : '#333333',
      fontSize: '18px',
    },
    // '@keyframes spin': {
    //   '0%': { transform: 'rotate(0deg)' },
    //   '100%': { transform: 'rotate(360deg)' },
    // },
    queryContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
      position: 'relative',
      width: '100%',
      maxWidth: `${boxWidth * visibleBoxes}px`,
    },
    queriesWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: `${boxWidth * visibleBoxes}px`,
      overflow: 'hidden',
    },
    queriesSlider: {
      display: 'flex',
      transition: 'transform 0.3s ease-in-out',
    },
    queryButton: {
      backgroundColor: isDark ? '#2C2C2C' : '#E0E0E0',
      border: `1px solid ${isDark ? '#3A3A3A' : '#CCCCCC'}`,
      color: isDark ? '#E0E0E0' : '#333333',
      padding: '10px',
      textAlign: 'center',
      cursor: 'initial',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: isDark ? '#3A3A3A' : '#D0D0D0',
      },
      width: `${boxWidth - 20}px`,  // 20px for margins
      height: '110px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '12px',
      margin: '0 10px',
      flexShrink: 0,
      fontSize: '14px',
    },
    navButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      fontSize: '24px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
      zIndex: 1,
    },
    leftNavButton: {
      left: '-40px',
    },
    rightNavButton: {
      right: '-40px',
    },
    placeholderText: {
      color: isDark ? '#E0E0E0' : '#333333',
      fontSize: '1.2em',
    },
    // placeholderText: {
    //   color: isDark ? '#E0E0E0' : '#333333',
    //   fontSize: '0.9em',
    // },
    pdfInfo: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    fileName: {
      fontSize: '14px',
      marginBottom: '0px',
      color: isDark ? '#E0E0E0' : '#333333',
      textAlign: 'center',
    },
    pageNum: {
      fontSize: '14px',
      marginBottom: '30px',
      color: isDark ? '#E0E0E0' : '#333333',
      textAlign: 'center',
    },
    securityNote: {
      textAlign: 'center',
      fontSize: '0.8em',
      color: isDark ? '#9E9E9E' : '#666666',
      marginTop: '10px',
    },
    contextPopupOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    contextPopup: {
      backgroundColor: isDark ? '#2C2C2C' : '#FFFFFF',
      borderRadius: '10px',
      width: '70%',
      height: '85%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: '2px solid #3498DB',
    },
    contextPopupHeader: {
      position: 'sticky',
      top: 0,
      backgroundColor: isDark ? '#2C2C2C' : '#FFFFFF',
      padding: '10px',
      paddingLeft: '20px',
      borderBottom: '1px solid #3498DB',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 1001,
    },
    contextPopupTitle: {
      color: isDark ? '#E0E0E0' : '#333333',
      fontSize: '20px',
      fontWeight: 'bold',
      margin: 0,
    },
    contextPopupCloseButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: '#3498DB',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contextPopupContent: {
      padding: '10px 20px',
      color: isDark ? '#E0E0E0' : '#333333',
      flex: 1,
      overflowY: 'auto',
    },
    contextPopupParagraph: {
      marginBottom: '10px',
    },
    contextPopupSourceIcon: {
      color: '#3498DB',
      marginLeft: '4px',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    questionActions: {
      display: 'flex',
      gap: '2px', // Decreased spacing between icons
    },
    loginContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      backgroundColor: isDark ? '#1E1E1E' : '#FFFFFF',
    },
    loginTitle: {
      fontSize: '2.5em',
      marginBottom: '30px',
      color: isDark ? '#E0E0E0' : '#333333',
      textAlign: 'center',
    },
    pavoniaText: {
      color: isDark ? '#FF79C6' : '#bd93f9',
    },
    loginButtonContainer: {
      marginTop: '20px',
    },
    topRightIcons: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      display: 'flex',
      alignItems: 'center',
      zIndex: 1000,
    },
    iconButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: isDark ? '#E0E0E0' : '#333333',
      cursor: 'pointer',
      marginLeft: '15px',
      padding: '5px',
      transition: 'color 0.3s',
      '&:hover': {
        color: isDark ? '#3498DB' : '#2980B9',
      },
    },
    userMessageContainer: {
      position: 'relative',
      alignSelf: 'flex-end',
      maxWidth: '70%',
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    editButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: isDarkMode ? '#FF79C6' : '#bd93f9', // Brighter, more visible color
      padding: '5px',
      borderRadius: '5px',
      transition: 'color 0.3s',
      position: 'absolute',
      left: '-30px',
      '&:hover': {
        color: isDarkMode ? '#ff99d2' : '#d4b6fa',
      }
    },
    userMessage: {
      alignSelf: 'flex-end',
      backgroundColor: isDarkMode ? '#3498DB' : '#2980B9',
      color: 'white',
      padding: '10px 15px',
      borderRadius: '15px 15px 0 15px',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      width: '100%',
      textAlign: 'left'
    },
    userMessageWrapper: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%'
    },
    actionButton: (isClicked) => ({
      backgroundColor: isClicked ? '#3498DB' : 'transparent', // Blue when clicked
      border: 'none',
      color: isClicked ? 'white' : (isDark ? '#9E9E9E' : '#666666'),
      cursor: 'pointer',
      marginLeft: '10px',
      padding: '5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: isClicked ? '#3498DB' : 'transparent',
        color: isDark ? '#E0E0E0' : '#333333',
      },
    })
  });

  const styles = getStyles(isDarkMode, isSidebarVisible);
  const updatedStyles = {
    ...styles,
    editButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: isDarkMode ? '#9E9E9E' : '#666666',
      padding: '5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s, color 0.3s',
    },
    messageActionsVisible: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '5px',
      opacity: 1,
    },
    messageActionsHidden: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '5px',
      opacity: 0,
    }
  };

  styles.clipboardButton = {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    backgroundColor: isDarkMode ? '#252525' : '#FFFFFF',
    color: isDarkMode ? '#E0E0E0' : '#333333',
    border: `1px solid ${isDarkMode ? '#3d3d3d' : '#e1e1e1'}`,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1000,
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    transition: 'transform 0.2s ease, background-color 0.2s ease',
    '&:hover': {
      transform: 'scale(1.1)',
      backgroundColor: isDarkMode ? '#3d3d3d' : '#F0F0F0'
    }
  };

  styles.sidebarItem = {
    ...styles.sidebarItem,
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: isDarkMode ? '#3A3A3A' : '#E0E0E0',
    },
  };

  styles.pdfInfo = {
    width: '100%',
    padding: '0 10px',
    boxSizing: 'border-box',
    marginTop: '-10px',
    height: '100px', // Fixed height for info section
  };
  
  styles.infoContainer = {
    marginBottom: '30px',
  };
  
  styles.buttonAndPageContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5px',
  };
  
  styles.infoButtons = {
    display: 'flex',
    gap: '5px',
  };
  
  styles.infoButton = {
    padding: '4px 8px',
    border: `1px solid ${isDarkMode ? '#FF79C6' : '#bd93f9'}`,
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '12px',
    fontWeight: '500',
  };
  
  styles.infoText = {
    fontSize: '14px',
    color: isDarkMode ? '#E0E0E0' : '#333333',
    marginBottom: '5px',
    wordBreak: 'break-word',
  };
  
  styles.pageNum = {
    fontSize: '14px',
    color: isDarkMode ? '#E0E0E0' : '#333333',
    margin: 0,
  };

  styles.editContainer = {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
  };
  
  styles.editInput = {
    flex: 1,
    padding: '4px 8px',
    fontSize: '14px',
    backgroundColor: isDarkMode ? '#1E1E1E' : '#FFFFFF',
    color: isDarkMode ? '#E0E0E0' : '#333333',
    border: `1px solid ${isDarkMode ? '#3A3A3A' : '#CCCCCC'}`,
    borderRadius: '4px',
  };
  
  styles.saveButton = {
    padding: '4px 8px',
    backgroundColor: isDarkMode ? '#FF79C6' : '#bd93f9',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };
  
  styles.editIcon = {
    marginLeft: '8px',
    cursor: 'pointer',
    color: isDarkMode ? '#666666' : '#999999',
    '&:hover': {
      color: isDarkMode ? '#E0E0E0' : '#333333',
    },
  };
  
  styles.referenceContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  styles.pdfImage = {
    maxWidth: '100%',
    maxHeight: '90vh',
    objectFit: 'contain',
    marginTop: '0px',
    marginBottom: '10px', // Increased from -3px to 30px
  };

  if (isLoading2) {
    return <div style={styles.loadingOverlay}>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      {!isAuthenticated ? (
        <div style={styles.loginContainer}>
          <h1 style={styles.loginTitle}>
            Welcome to <span style={styles.pavoniaText}>PAVONIA</span>
          </h1>
          <div style={styles.loginButtonContainer}>
            <LoginButton isDarkMode={isDarkMode} />
          </div>
        </div>
      ) : (
        <>
          {/* Sidebar */}
          <div style={styles.sidebar}>
            <h2 style={styles.sidebarTitle}>PAVONIA</h2>
            <h2 style={styles.sidebarTitle2}>Internal Knowledge Center</h2>
            <button style={styles.newChatButton} onClick={startNewChat} aria-label="New Chat">
              <Plus size={24} />
            </button>
            <div style={styles.sidebarSection}>
              <h3 style={styles.sidebarSectionTitle}>Recent Chats</h3>
              {recentChats.map((chat, index) => (
                <p 
                  key={chat._id} 
                  style={{...styles.sidebarItem, cursor: 'pointer'}}
                  onClick={() => handleRecentChatClick(chat.query)}
                  title={chat.query}
                >
                  {truncateText(chat.query, 30)}
                </p>
              ))}
            </div>
          </div>

          {/* clipboard button and panel */}
          {isAuthenticated && (
            <>
              <button
                onClick={toggleClipboard}
                style={styles.clipboardButton}
                title="Toggle clipboard history"
              >
                <Clipboard size={20} />
              </button>

              <button
                onClick={() => setIsBatchQueriesOpen(true)}
                style={{
                  ...styles.clipboardButton,
                  left: '70px'  // Position it next to the clipboard button
                }}
                title="Open batch queries"
              >
                <ClipboardList size={20} />
              </button>

              <ClipboardPanel
                isOpen={isClipboardOpen}
                clipboardItems={clipboardItems}
                onRemoveItem={removeClipboardItem}
                onClearAll={clearClipboard}
                isDarkMode={isDarkMode}
                onClose={() => setIsClipboardOpen(false)}
              />

              <BatchQueriesPopup
                isOpen={isBatchQueriesOpen}
                onClose={() => setIsBatchQueriesOpen(false)}
                isDarkMode={isDarkMode}
                sendMessage={sendMessageStream}
                handleDocumentClick={handleDocumentClick}
                addToClipboard={addToClipboard}
                kLimit={kLimit}
                isFilteredSearch={isFilteredSearch}
                filteredFileName={filteredFileName}
              />
            </>
          )}

          <HelpPopup
            isOpen={isHelpPopupOpen}
            onClose={() => setIsHelpPopupOpen(false)}
            content={helpPopupContent}
            isDarkMode={isDarkMode}
            isLoading={isHelpLoading}
            onSendQuery={sendMessageStream}
            onCopy={(text) => {  // Add this prop
              navigator.clipboard.writeText(text)
                .then(() => {
                  addToClipboard(text);
                })
                .catch(err => console.error('Failed to copy text: ', err));
            }}
          />
      
          <ContextPopup
            isOpen={isPopupOpen}
            onClose={handlePopupClose}
            context={contextAlt}
            systemResponse={systemResponseForContext}
            isDarkMode={isDarkMode}
            handleDocumentClick={handleDocumentClick}
            idMapping={currentIdMapping}
            addToClipboard={addToClipboard}
          />
      
          <button style={styles.sidebarToggle} onClick={toggleSidebar}>
            {isSidebarVisible ? <ChevronLeft /> : <ChevronRight />}
          </button>
      
          {!isSidebarVisible && (
            <button 
              style={{...styles.newChatButton, ...styles.newChatButtonHidden}} 
              onClick={startNewChat} 
              aria-label="New Chat"
            >
              <Plus size={24} />
            </button>
          )}
      
          {/* Loading overlay */}
          {isLoading && (
            <div style={styles.loadingOverlay}>
              <div style={styles.spinner}></div>
            </div>
          )}
      
          {/* Main Content Wrapper */}
          <div style={styles.mainContentWrapper}>
            <div style={styles.chatColumn}>
              {isFilteredSearch && (
                <div style={{
                  padding: '12px 20px',
                  margin: '10px 0',
                  backgroundColor: isDarkMode ? 'rgba(255, 121, 198, 0.1)' : 'rgba(189, 147, 249, 0.1)',
                  color: isDarkMode ? '#FF79C6' : '#bd93f9',
                  fontSize: '1.0em', // Increased from 0.9em
                  fontWeight: '500', // Added for better visibility
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderRadius: '8px',
                  border: `1px solid ${isDarkMode ? 'rgba(255, 121, 198, 0.3)' : 'rgba(189, 147, 249, 0.3)'}`,
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)' // Added subtle shadow
                }}>
                  <span>Filtered search: {filteredFileName}</span>
                  <button
                    onClick={disableFilteredSearch}
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: '4px',
                      cursor: 'pointer',
                      color: isDarkMode ? '#FF79C6' : '#bd93f9',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <X size={16} />
                  </button>
                </div>
              )}
              {conversation.length === 0 ? (
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                  <div style={styles.warningMessage2}>
                    Answers to frequently-asked questions:
                  </div>
                  <div style={styles.queryContainer}>
                    <button onClick={() => handleScroll(-1)} style={{...styles.navButton, ...styles.leftNavButton}}>
                      <ChevronLeft />
                    </button>
                    <div style={styles.queriesWrapper}>
                      <div style={styles.queriesSlider}>
                        {getVisibleQueries().map((query, index) => (
                          <button 
                            key={index}
                            style={styles.queryButton}
                          >
                            {query}
                          </button>
                        ))}
                      </div>
                    </div>
                    <button onClick={() => handleScroll(1)} style={{...styles.navButton, ...styles.rightNavButton}}>
                      <ChevronRight />
                    </button>
                  </div>
                </div>
              ) : (
                <div ref={conversationRef} style={styles.conversation}>
                  {conversation.map((c, index) => (
                    <div key={index} style={{
                      ...styles.message,
                      alignSelf: c.from === 'user' ? 'flex-end' : 'flex-start',
                      maxWidth: c.from === 'user' ? '70%' : '100%',
                    }}>
                      {c.from === 'user' ? (
                        <div style={styles.userMessageWrapper}>            
                          {editingMessageIndex === index ? (
                            <EditableMessage
                              text={c.text}
                              isEditing={true}
                              onSave={(newText) => handleSaveEdit(newText, index)}
                              onCancel={handleCancelEdit}
                              isDarkMode={isDarkMode}
                            />
                          ) : (
                            <EditableMessage
                              text={c.text}
                              isEditing={false}
                              onSave={(newText) => handleSaveEdit(newText, index)}
                              onCancel={handleCancelEdit}
                              isDarkMode={isDarkMode}
                              onEdit={() => handleEditMessage(index)}
                            />
                          )}
                        </div>
                      ) : (
                        <SystemMessage 
                          message={c.text} 
                          handleDocumentClick={(sourceId, idMapping) => handleDocumentClick(sourceId, idMapping, index)}
                          activeDocumentId={activeDocumentId} 
                          idMapping={c.idMapping}
                          messageIndex={index}
                          iconStates={messageIconStates}
                          activeDocRefs={activeDocRefs} 
                        />
                      )}
                      {c.from === 'bot' && (
                        <div style={styles.messageActions}>
                          <button
                            key="help"
                            style={{
                              ...styles.actionButton(clickedIcons[`help-${index}`]),
                              marginRight: '0px'
                            }}
                            onClick={() => handleActionButton('help', index)}
                            aria-label="Help"
                          >
                            <HelpingHand size={20} />
                          </button>
                          <button
                            key="more"
                            style={{
                              ...styles.actionButton(clickedIcons[`more-${index}`]),
                              marginRight: '10px'
                            }}
                            onClick={() => handleActionButton('more', index)}
                            aria-label="More options"
                          >
                            <BookOpenText size={18} />
                          </button>
                          {['like', 'dislike', 'copy', 'regenerate'].map((action) => (
                            <button
                              key={action}
                              style={styles.actionButton(clickedIcons[`${action}-${index}`])}
                              onClick={() => handleActionButton(action, index)}
                              aria-label={action.charAt(0).toUpperCase() + action.slice(1)}
                            >
                              {action === 'like' && <ThumbsUp size={16} />}
                              {action === 'dislike' && <ThumbsDown size={16} />}
                              {action === 'copy' && <Copy size={16} />}
                              {action === 'regenerate' && <RefreshCw size={16} />}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div style={styles.inputArea}>
                <div 
                  style={styles.inputWrapper}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <div style={styles.loadingIndicatorContainer}>
                    {(isLoading || isHelpLoading || isWaitingForResponse) && 
                      <LoadingIndicator isLoading={isLoading || isHelpLoading || isWaitingForResponse} isDarkMode={isDarkMode} />}
                  </div>
                  <ContentEditableInput
                    onSend={sendMessageStream}
                    isDarkMode={isDarkMode}
                    isQueryPending={isQueryPending}
                    cancelQuery={cancelQuery}
                  />
     
                  {isDragging && (
                    <div style={styles.draggingOverlay}>
                      Drop file here
                    </div>
                  )}
                </div>
                <div style={styles.warningMessage}>
                  Please verify all outputs by reviewing the source document(s).
                </div>
              </div>
            </div>
          </div>
      
          {/* Right Sidebar */}
          <div style={styles.rightSidebar}>
            <div style={styles.pdfViewerColumn}>
              {isInitialDocumentLoad && isLoading ? (
                <SkeletonLoader isDarkMode={isDarkMode} fullHeight={true} />
              ) : (
                <div style={styles.pdfContainer}>
                  {isRightSidebarVisible && (
                    <div style={styles.rightSidebar}>
                      <PDFViewer
                        isLoading={isLoading}
                        serverError={serverError}
                        isRightSidebarVisible={isRightSidebarVisible}
                        pdfBase64={pdfBase64}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        fileName={fileName}
                        isDarkMode={isDarkMode}
                        onDownload={handleDownload}
                        onPageChange={handlePageChange}
                        isFilteredSearch={isFilteredSearch}
                        currentReference={currentReference}
                        onReferenceUpdate={handleReferenceUpdate}
                        showImage={showImage}
                        onToggleImage={() => setShowImage(!showImage)}
                        pdfImageUrl={pdfImageUrl}
                      />
                    </div>
                  )}
                  {showImage ? (
                    pdfImageUrl ? (
                      <img
                        src={pdfImageUrl}
                        alt={`PDF Page ${currentPage} with Bounding Box`}
                        style={styles.pdfImage}
                        onError={() => setImageError("Failed to load image")}
                      />
                    ) : (
                      <div style={styles.errorMessage}>{imageError || "Image not available"}</div>
                    )
                  ) : (
                    pdfBase64 ? (
                      <iframe
                        src={`data:application/pdf;base64,${pdfBase64}#page=${currentPage}`}
                        style={styles.pdfViewer}
                        title="PDF Viewer"
                        onError={() => setPdfError("Failed to load PDF")}
                      />
                    ) : (
                      <div style={styles.errorMessage}>{pdfError || "PDF not available"}</div>
                    )
                  )}
                  {fileName && (
                    <div style={styles.pdfInfo}>
                      <div style={styles.infoContainer}>
                        <p style={styles.infoText}>
                          {displayMode === 'filename' ? fileName : (
                            <>
                              {isEditingRef ? (
                                <div style={styles.editContainer}>
                                  <input
                                    type="text"
                                    value={editedRef}
                                    onChange={(e) => setEditedRef(e.target.value)}
                                    style={styles.editInput}
                                    onKeyPress={(e) => e.key === 'Enter' && handleReferenceUpdate()}
                                  />
                                  <button onClick={handleReferenceUpdate} style={styles.saveButton}>Save</button>
                                </div>
                              ) : (
                                <div style={styles.referenceContainer}>
                                  <span>{currentReference || 'No reference available'}</span>
                                  <Edit
                                    size={16}
                                    style={styles.editIcon}
                                    onClick={() => {
                                      setIsEditingRef(true);
                                      setEditedRef(currentReference);
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </p>
                        <div style={styles.buttonAndPageContainer}>
                          <div style={styles.infoButtons}>
                            <button
                              onClick={() => setDisplayMode('filename')}
                              style={{
                                ...styles.infoButton,
                                backgroundColor: displayMode === 'filename' 
                                  ? (isDarkMode ? '#FF79C6' : '#bd93f9')
                                  : 'transparent',
                                color: displayMode === 'filename'
                                  ? 'white'
                                  : (isDarkMode ? '#E0E0E0' : '#333333')
                              }}
                            >
                              File
                            </button>
                            <button
                              onClick={() => setDisplayMode('reference')}
                              style={{
                                ...styles.infoButton,
                                backgroundColor: displayMode === 'reference'
                                  ? (isDarkMode ? '#FF79C6' : '#bd93f9')
                                  : 'transparent',
                                color: displayMode === 'reference'
                                  ? 'white'
                                  : (isDarkMode ? '#E0E0E0' : '#333333')
                              }}
                            >
                              Ref
                            </button>
                          </div>
                          <p style={styles.pageNum}>Page: {currentPage} of {totalPages}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
      
          {/* Right Sidebar Toggle Button */}
          {hasSystemOutput && !isNewChat && !isPopupOpen && !isHelpPopupOpen && (
            <button style={styles.rightSidebarToggle} onClick={toggleRightSidebar}>
              {isRightSidebarVisible ? <ChevronRight /> : <ChevronLeft />}
            </button>
          )}
        
        {/* Top Right Icons */}
        <div style={styles.topRightIcons}>
          <KLimitSetting
            kLimit={kLimit}
            onKLimitChange={setKLimit}
            isDarkMode={isDarkMode}
            isOpen={isKLimitSettingsOpen}
            onToggle={() => setIsKLimitSettingsOpen(!isKLimitSettingsOpen)}
          />
          <button
            style={styles.iconButton}
            onClick={toggleDarkMode}
            aria-label={isDarkMode ? "Light Mode" : "Dark Mode"}
          >
            {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
            </button>
            <button
              style={styles.iconButton}
              onClick={handleLogout}
              aria-label="Logout"
            >
              <LogOut size={24} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default App;
